import * as React from 'react';
import { marked } from 'marked';
import CodeBlock from '../CodeBlock';
import ContentSnippet from '../ContentSnippet';
import Page from '../Page';
import Row from '../Row';
import Section from '../Section';
import Tag from '../Tag';
import { Body, Heading, TextWrapper } from '../Text';
import ContentImage from '../ContentImage/ContentImage';
import { IPost } from '../../requests/findPosts';
import PostList from '../PostList/PostList';

interface ExemplarPostProps extends IPost {
  location: any;
}

const ExemplarPost = ({
  location,
  title,
  uuid: postUuid,
  tags,
  contentSnippets,
  codeSnippets,
  body,
  description,
  resources,
  related,
  keywords,
  hero,
  codeImage,
  infographic,
}: ExemplarPostProps) => {
  return (
    <Page
      location={location}
      title={title}
      description={description}
      keywords={keywords}
    >
      <Section>
        <Heading.h1>{title}</Heading.h1>
        <ContentImage maxHeight={250} image={hero} alt={title} />
        <Row columnGap={12} rowGap={12}>
          {tags.map(({ name, uuid }) => (
            <Tag key={uuid} title={name} />
          ))}
        </Row>
      </Section>
      <Section>
        <TextWrapper>
          <div
            dangerouslySetInnerHTML={{ __html: marked.parse(description) }}
            itemProp="articleBody"
          />
        </TextWrapper>
      </Section>
      {codeSnippets.map((cs) => (
        <Section key={cs.code}>
          {cs.preText && (
            <TextWrapper>
              <div
                dangerouslySetInnerHTML={{ __html: marked.parse(cs.preText) }}
                itemProp="articleBody"
              />
            </TextWrapper>
          )}
          <CodeBlock {...cs} />
          {cs.postText && (
            <TextWrapper>
              <div
                dangerouslySetInnerHTML={{ __html: marked.parse(cs.postText) }}
                itemProp="articleBody"
              />
            </TextWrapper>
          )}
        </Section>
      ))}

      <Section>
        <TextWrapper>
          <section
            dangerouslySetInnerHTML={{ __html: marked.parse(body) }}
            itemProp="articleBody"
          />
        </TextWrapper>
      </Section>
      <Section>
        <ContentImage image={infographic} alt={`${title} infographic`} />
      </Section>
      {contentSnippets.map(({ title, content, uuid, images }) => (
        <React.Fragment key={uuid}>
          <ContentSnippet
            title={title}
            content={marked.parse(content)}
            images={images}
          />
        </React.Fragment>
      ))}
      <Section>
        <Heading.h3>Further resources</Heading.h3>
        <Row flexDirection="column" rowGap={6}>
          {resources.map(({ title: resourceTitle, href, uuid }) => (
            <Body.a key={uuid} href={href} external>
              {resourceTitle}
            </Body.a>
          ))}
        </Row>
      </Section>
      <Section>
        <Heading.h3>Related topics</Heading.h3>
        <PostList posts={related as IPost[]} />
      </Section>
      <Section>
        <Body.p>
          <strong>Save code?</strong>
        </Body.p>
        <ContentImage
          maxWidth={300}
          image={codeImage}
          alt={`${title} code example`}
        />
      </Section>
    </Page>
  );
};

export default ExemplarPost;
