import React from 'react';
import { container, copy } from './Tag.module.css';

interface TagProps {
  title: string;
}

export default function Tag({ title }: TagProps) {
  return (
    <a
      href={`/definition/${title.toLowerCase().replaceAll(' ', '')}/`}
      className={container}
    >
      <span className={copy}>{title}</span>
    </a>
  );
}
