import * as React from 'react';
import { IPost } from '../requests/findPosts';
import ExemplarPost from '../components/ExemplarPost/ExemplarPost';

const ExemplarPageTemplate = ({
  location,
  pageContext,
}: {
  pageContext: IPost;
  location: any;
}) => {
  return (
    <ExemplarPost {...pageContext} location={location} />
  );
};

export default ExemplarPageTemplate;
